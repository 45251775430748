import { ReactNode } from 'react';
import styled from 'styled-components';
import { ColumnFlexbox, VerticallyCenteredFlexWithSpaceBetween } from '../../../typography/flex';
import { Colors } from '../../../themes/colors';
import { IconClickable } from '../IconClickable';

export type DialogProps = {
  headerContent?: ReactNode;
  body: ReactNode;
  onClose: () => void;
  className?: string;
  showCloseIcon?: boolean;
};

export const Dialog = ({ showCloseIcon = true, ...props }: DialogProps) => {
  const { headerContent, body, onClose, className } = props;

  return (
    <DialogComponent className={className}>
      {headerContent && (
        <DialogHeader className="headerClassName">
          {headerContent}

          {showCloseIcon && <CloseIcon onClick={onClose} icon="/assets/icons.svg#close" />}
        </DialogHeader>
      )}
      <DialogBody className="bodyClassName">{body}</DialogBody>
    </DialogComponent>
  );
};

const DialogComponent = styled(ColumnFlexbox)`
  background: ${Colors.MainBackground};
  border-radius: 0.625rem;
`;
const DialogHeader = styled(VerticallyCenteredFlexWithSpaceBetween)`
  padding: 1.125rem 1.25rem 1.125rem 1.875rem;
  border-bottom: 1px solid ${Colors.Grey600};
`;

const DialogBody = styled(ColumnFlexbox)`
  padding: 1.5625rem 1.875rem 1.875rem 1.875rem;
`;

const CloseIcon = styled(IconClickable)`
  fill: ${Colors.Grey700} !important;

  :hover {
    fill: ${Colors.Blue600} !important;
  }

  :active {
    fill: ${Colors.Blue700} !important;
  }
`;
