import { Supplier } from 'app/models/supplier';
import { Call } from 'app/models/call';
import { Appeal } from 'app/models/appeal';
import { CommonFiltersStructure } from 'app/models/configuration/table-structure';
import { DefaultQueryParams } from 'app/models/common';
import { getCreateActionTrio, getCreatePayloadActionFromStore } from '../helpers/action-create';
import { ChangeChosenFilterPayload } from '../models/change-chosen-filter-payload';

export const SUPPLIERS_STORE_NAME = 'suppliers';

const createActionTrio = getCreateActionTrio(SUPPLIERS_STORE_NAME);
const createPayloadAction = getCreatePayloadActionFromStore(SUPPLIERS_STORE_NAME);

export const GetSuppliersForTable = createActionTrio<
  DefaultQueryParams,
  { page: number; suppliersTotalCount: number; suppliers: Supplier[] }
>('get suppliers for table');

export const GetSuppliers = createActionTrio<void, Supplier[]>('get suppliers');

export const GetSupplierById = createActionTrio<string, Supplier>('get supplier by id');

export const LoadSupplierAppeals = createActionTrio<string, Appeal[]>('load supplier appeals');

export const LoadSupplierCalls = createActionTrio<string, Call[]>('load supplier calls');

export const setChosenFilter = createPayloadAction<{ filters: CommonFiltersStructure }>('set chosen filter');
export const changeChosenFilter = createPayloadAction<ChangeChosenFilterPayload>('change chosen filter');
export const resetSomeFilters = createPayloadAction<string[]>('reset some filters');
