import { useDispatch } from 'react-redux';
import { AppealsActions } from 'store/appeals';
import { PayloadAction } from '@reduxjs/toolkit';
import { Appeal } from 'app/models/appeal';
import { AppealActionType } from 'app/models/appeal/appeal-action-type';
import { webphoneActions } from 'store/webphone';
import { APPEAL_CATEGORIES, APPEAL_STATUS } from 'app/constants/appeals';
import React from 'react';
import { ModalService } from '../../services/modal-service/modal.service';
import { ConfirmChangeStatusModal } from '../../pages/Appeal/dialogs/ConfirmChangeStatusModal/ConfirmChangeStatusModal';
import { WorkPlanDialog } from '../../pages/Appeal/dialogs/WorkPlanDialog';

export const useAppealActions = (appeal?: Appeal) => {
  const dispatch = useDispatch();

  const updateAppealStatus = React.useCallback(
    (status: string) => {
      if (!appeal?.id) return;
      return AppealsActions.ChangeAppealStatus.init({ id: appeal?.id, status });
    },
    [appeal]
  );

  const updateAppealStatusInTheWork = React.useCallback(() => {
    if (!appeal?.id) return;

    if (
      !appeal?.isPayment &&
      !appeal?.planned_end_date &&
      !appeal?.performingStartedAtPlan &&
      appeal?.type === APPEAL_CATEGORIES.requisition
    ) {
      ModalService.openModal(WorkPlanDialog, {
        textButton: 'В работу',
        showCloseIcon: false,
        onClose: () => dispatch(AppealsActions.ChangeAppealStatus.init({ id: appeal?.id, status: APPEAL_STATUS.inTheWork })),
      });
    } else {
      return AppealsActions.ChangeAppealStatus.init({ id: appeal?.id, status: APPEAL_STATUS.inTheWork });
    }
  }, [appeal]);

  const openConfirmModalHandler = React.useCallback(
    (status: string) => {
      ModalService.openModal(ConfirmChangeStatusModal, { appealId: appeal?.id, status, onClose: () => {} });
    },
    [appeal?.id]
  );

  const addFeedback = React.useCallback(
    (appeal: Appeal) => {
      if (appeal.workEvaluation) {
        return updateAppealStatus(APPEAL_STATUS.closed);
      }
      return AppealsActions.openAddFeedbackDialog({ appeal });
    },
    [updateAppealStatus]
  );

  const getPayloadAction = React.useCallback(
    (appealActionType: AppealActionType | string, appeal: Appeal): PayloadAction<any, string> | undefined | void => {
      switch (appealActionType) {
        case AppealActionType.ChangeStatusToCanceled:
          return openConfirmModalHandler(APPEAL_STATUS.canceled);
        case AppealActionType.ChangeStatusToInTheWork:
          return updateAppealStatusInTheWork();
        case AppealActionType.ChangeStatusToPostponed:
          return openConfirmModalHandler(APPEAL_STATUS.postponed);
        case AppealActionType.ChangeStatusToCompleted:
          return updateAppealStatus(APPEAL_STATUS.completed);
        case AppealActionType.ChangeStatusToClosed:
          return addFeedback(appeal);
        case AppealActionType.EditAppeal:
          return AppealsActions.NavigateToUpdateAppealPage.init({ id: appeal.id });
        case AppealActionType.CallConsumer:
          return appeal.consumer?.phone
            ? webphoneActions.InitOutgoingRinging.init({
                phone: appeal.consumer.phone,
                options: { prefix: appeal?.managementCompany?.prefix },
              })
            : undefined;
        case AppealActionType.CallMaster:
          return webphoneActions.InitOutgoingRinging.init({
            phone: appeal.handyman.phone,
            options: { prefix: appeal?.managementCompany?.prefix },
          });
        case AppealActionType.AssignMaster:
          return AppealsActions.openSelectMasterDialog({ appeal });
        case AppealActionType.EditWorkPlan:
          return AppealsActions.openWorkPlanDialog();
        case AppealActionType.ChangeSupplier:
          return AppealsActions.openSelectSupplierDialog({ appealId: appeal.id });
        default:
          return undefined;
      }
    },
    [addFeedback, openConfirmModalHandler, updateAppealStatus]
  );

  const dispatchAction = React.useCallback(
    (appealActionType: AppealActionType | string) => {
      if (appeal) {
        const action = getPayloadAction(appealActionType, appeal);
        if (action) {
          dispatch(action);
        }
      }
    },
    [appeal, dispatch, getPayloadAction]
  );

  return {
    dispatchAction,
  };
};
