import { Consumer, ConsumerId, ConsumersQueryParams } from 'app/models/consumer';
import { Call } from 'app/models/call';
import { CommonFiltersStructure } from 'app/models/configuration';
import { Appeal } from 'app/models/appeal';
import { getCreateActionTrio, getCreatePayloadActionFromStore } from '../helpers/action-create';
import { ChangeChosenFilterPayload } from '../models/change-chosen-filter-payload';
import { CreateConsumerPayload, UpdateConsumerPayload } from './models';

export const CONSUMERS_STORE_NAME = 'consumers-state';

const createPayloadAction = getCreatePayloadActionFromStore(CONSUMERS_STORE_NAME);
const createActionTrio = getCreateActionTrio(CONSUMERS_STORE_NAME);

export const LoadConsumers = createActionTrio<ConsumersQueryParams, { page: number; consumersTotalCount: number; consumers: Consumer[] }>(
  'load consumers'
);

export const LoadSelectedConsumer = createActionTrio<{ id: string }, Consumer>('load selected consumer');

export const CreateConsumer = createActionTrio<CreateConsumerPayload, Consumer>('create consumer');

export const UpdateConsumer = createActionTrio<UpdateConsumerPayload, Consumer>('update consumer');

export const DeleteConsumer = createActionTrio<{ id: ConsumerId }, { id: ConsumerId }>('delete consumer');

export const DeleteConsumerAccommodation = createActionTrio<string, string>('delete consumer accommodation');

export const LoadConsumerAppeals = createActionTrio<string, Appeal[]>('load consumer appeals');

export const LoadConsumerCalls = createActionTrio<string, Call[]>('load consumer calls');

export const setChosenFilter = createPayloadAction<{ filters: CommonFiltersStructure }>('set chosen filter');
export const changeChosenFilter = createPayloadAction<ChangeChosenFilterPayload>('change chosen filter');
export const resetSomeFilters = createPayloadAction<string[]>('reset some filters');

export const NavigateToUpdateConsumerPage = createActionTrio<{ id: ConsumerId }, void>('navigate to update consumer page');
